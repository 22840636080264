import { useRef, useEffect, useState } from "react";
import './assets/scss/styles.scss';

function App() {
    const [canvasContext, setCanvasContext] = useState(null);
    const canvasRef = useRef(null)
    const imgRef = useRef(null)
    const downloadButtonRef = useRef(null)
    let widthImageUpload = 0
    let heigthImageUpload = 0

    const handleImage = (e) => {
        if (e.target === imgRef.current) {
            let imgUploaded = new Image();
            imgUploaded.src = URL.createObjectURL(e.target.files[0]);
            imgUploaded.onload = function () {
                canvasRef.current.width = this.width;
                canvasRef.current.height = this.height;
                widthImageUpload = this.width;
                heigthImageUpload = this.height;
                canvasContext.drawImage(imgUploaded, 0, 0, imgUploaded.width, imgUploaded.height);
            }
        }

        downloadButtonRef.current.style.display = 'block';

        console.log('success upload !')

        let watermark = new Image();
        watermark.src = './img/watermark2.png'
        watermark.onload = function () {
            canvasContext.globalAlpha = 0.5;
            let widthWatermark = widthImageUpload;
            let heightWatermark = widthImageUpload * (this.height / this.width);
            let axisY = (heigthImageUpload / 2) - (heightWatermark / 2);
            canvasContext.drawImage(watermark, 0, axisY, widthWatermark, heightWatermark);
        }

        console.log('success add watermark !')
    }


    const download = () => {
        let datetime = new Date().valueOf();
        let filename = `infinite_${datetime}.png`
        let lnk = document.createElement('a'), e;
        lnk.download = filename;
        lnk.href = canvasRef.current.toDataURL("image/png;base64");

        if (document.createEvent) {
            e = document.createEvent("MouseEvents");
            e.initMouseEvent("click", true, true, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
            lnk.dispatchEvent(e);
        } else if (lnk.fireEvent) {
            lnk.fireEvent("onclick");
        }
    }

    useEffect(() => {
        const canvas = canvasRef.current;
        const context = canvas.getContext("2d");
        setCanvasContext(context);

        canvas.width = 0;
        canvas.height = 0;

    }, []);

    return (
        <div className="container">
            <div className="content">
                <div className="upload-outer">
                    <span className="drag-box">
                        <div className="drag-content">
                            <img className="icon-img" src="https://cdn-icons-png.flaticon.com/512/1160/1160358.png" alt="img icon" />
                            <div className="text">คลิก หรือ ลาก เพื่ออัพโหลดรูป</div>
                            <div className="remark">*รองรับเฉพาะไฟล์ JPG และ PNG</div>
                        </div>
                        <input ref={imgRef} type="file" id="uploadImg" onChange={handleImage} />
                    </span>
                </div>

                <canvas ref={canvasRef}></canvas>
                <button ref={downloadButtonRef} style={{ display: 'none' }} onClick={download}>ดาวน์โหลด</button>
            </div>
        </div>
    );
}

export default App;
